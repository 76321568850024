import React, { createContext, useContext, useState } from "react";
import trim_tree from "plant_variables/trim_tree.json";
import full_tree from "plant_variables/full_tree.json";
import audit_data from "plant_variables/plantAuditList.json";
import { getSession, useSession } from "next-auth/client";
import Login from "pages/index";
import moment from "moment-timezone";

const AppContext = createContext();

var meter_tag_dict = {};
import LZString from "lz-string";

const iterate = (obj) => {
  Object.keys(obj).forEach((key) => {
    //console.log(`key: ${key}, value: ${obj[key]}`)

    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (Array.isArray(obj)) {
      } else {
        meter_tag_dict[obj["key"]] = obj["node_name"];
      }

      iterate(obj[key]);
    }
  });
};

export function AppWrapper(props) {
  const [session, loading] = useSession();
  const [plantTimeZone, setPlantTimezone] = useState("Asia/Kolkata");

  if (session && !loading) {
    try {
      const children = props.children;
      const treeObj = {
        ...trim_tree,
        nodes: trim_tree["nodes"].filter((value) =>
          session.user.plants["USER"].includes(value["plant_id"]),
        ),
      };

      const fullTreeObj = {
        ...full_tree,
        nodes: full_tree["nodes"].filter((value) =>
          session.user.plants["USER"].includes(value["plant_id"]),
        ),
      };

      const serialize = (o) => {
        return LZString.compressToEncodedURIComponent(JSON.stringify(o));
      };
      const deserialize = (s) => {
        return JSON.parse(LZString.decompressFromEncodedURIComponent(s));
      };

      const [showGlobal, setShowGlobal] = React.useState("0");
      const [showSV, setShowSV] = React.useState("0");
      const [showEV, setShowEV] = React.useState("0");
      const [showST, setShowST] = React.useState("0");
      const [showDeviceState, setShowDeviceState] = React.useState("0");

      const [show_unnormalized, setShowUnnormalized] = React.useState("1");
      const [noiseTickets, setNoiseTicket] = React.useState(true);
      const [closeTickets, setCloseTicket] = React.useState(false);
      const [overViewMode, setOverViewMode] = React.useState("best");
      const [parentName, setParentName] = React.useState("unknown");

      const handleNoiseTicketChange = (val) => {
        setNoiseTicket(val);
      };

      const handleCloseTicketChange = (val) => {
        setCloseTicket(val);
      };

      const changesetShowGlobal = (id) => {
        setShowGlobal(id);
      };
      const changesetShowSV = (id) => {
        setShowSV(id);
      };
      const changesetShowEV = (id) => {
        setShowEV(id);
      };
      const changesetShowST = (id) => {
        setShowST(id);
      };
      const changesetShowUnnormalized = (id) => {
        setShowUnnormalized(id);
      };
      const changeShowDeviceState = (id) => {
        setShowDeviceState(id);
      };
      function updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";
        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      }
      const createLinkForMeterData = (
        meter_id,
        plant_id,
        start_date,
        end_date,
        readings,
        meterName,
        page = "/meter-readings/slug",
      ) => {
        let temp = `${page}?plant_id=${plant_id}&meter_id=${meter_id}&start_date=${start_date}&end_date=${end_date}&s_ds=undefined&show_global=0&sv=0&ev=0&st=0&show_unnormalized=0
&derived_value=Pwr_Norm&all_siblings=false&graph_mode=meterDiagnosis&zs=0&ze=100&reading_type=&meterName=${meterName}`;
        //  console.log("temp>>>>>>>>>>>>>>>>>>>>",temp)
        temp = updateQueryStringParameter(temp, "rt_l", serialize(readings));
        return temp;
      };

      // console.log("createlink>>>>>>>>",createLinkForMeterData)

      const createLinkSiblingCompare = (
        meter_id,
        plant_id,
        start_date,
        end_date,
        readings,
        meterName,
        page = "/meter-readings/slug",
      ) => {
        let temp = `${page}?plant_id=${plant_id}&meter_id=${meter_id}&start_date=${start_date}&sibling_rt=${readings[0].value}&end_date=${end_date}&s_ds=undefined&show_global=0&sv=0&ev=0&st=0&show_unnormalized=0
&derived_value=Pwr_Norm&all_siblings=false&graph_mode=meterDiagnosis&zs=0&ze=100&reading_type=&meterName=${meterName}&c_r=${readings[0].label}`;
        temp = updateQueryStringParameter(temp, "rt_l", serialize(readings));
        return temp;
      };

      const getParentNameFromUrl = (pathname) => {
        // console.log("pathname ", pathname)
        if (pathname == "/availability") return "Availabilty";
        if (pathname == "/events/[slug]") return "Events";
        if (
          pathname == "/meter-heatmaps/[slug]" ||
          pathname == "/meter-mapview/[slug]" ||
          pathname == "/meter-readings/[slug]" ||
          pathname == "/sibling-heatmap/[slug]"
        )
          return "meterDataNew";
        if (
          pathname == "/notebooks" ||
          pathname == "/reports" ||
          pathname == "/reports/execute/[taskid]" ||
          pathname == "/reports/history/[taskid]"
        )
          return "Reports";
        if (pathname == "/rules" || pathname == "/rules/edit")
          return "Plant Rules";
        if (pathname == "/snapshot/[slug]") return "realtimeSnapshot";
        if (pathname == "/states/[slug]") return "States";
        if (pathname == "/tickets/[slug]") return "Tickets";
        if (pathname == "/tms-report") return "tmsReport";
        if (pathname == "/landing-page") return "landingPage";
        if (pathname == "/portfolio-report") return "portfolioReport";

        if (pathname == "/audit") return "Audit";
        if (pathname == "/finance") return "Finance";
        if (pathname == "/geoMap") return "portfolio";
        if (pathname == "/operations") return "Operations";
        if (pathname == "/overview") return "Overview";

        return "unknown";
      };

      const [currentMeter, setCurrentMeter] = useState(
        treeObj["nodes"][0]["meter_id"],
      );

      var plant_dict = {};
      var plant_id_dict = {};
      for (var k = 0; k < fullTreeObj.nodes.length; k++) {
        plant_dict[fullTreeObj.nodes[k].plant_id] = fullTreeObj.nodes[k].label;
        plant_id_dict[fullTreeObj.nodes[k].label] =
          fullTreeObj.nodes[k].plant_id;
      }

      // if (demoFlag == true) {
      //   var start_d = moment.unix(DEMODATE).startOf('day');
      //   var end_d = moment.unix(DEMODATE).endOf('day');
      // } else {
      //     var start_d = moment().startOf('day');
      //     console.log("start_d state.js>>>", start_d)
      //     var end_d = moment().endOf('day');
      //     console.log("end_d state.js>>>",end_d)
      // }

      // const [startDate, setStartDate] = useState(start_d.unix()
      // );
      // const [endDate, setEndDate] = useState(end_d.unix());

      var start_d = moment().startOf("day");
      // console.log("start_d state.js>>>", start_d)
      var end_d = moment().endOf("day");

      const [startDate, setStartDate] = useState(
        moment(start_d.format("YYYY-MM-DD HH:mm:ss"))
          .tz(plantTimeZone, true)
          .startOf("day")
          .unix(),
      );

      const [endDate, setEndDate] = useState(
        moment(end_d.format("YYYY-MM-DD HH:mm:ss"))
          .tz(plantTimeZone, true)
          .endOf("day")
          .unix(),
      );

      // async function setPlantTimezone() {
      //   try {
      //     if (plant_id != undefined) {
      //       const payload = {
      //         plant_id_list: [plant_id],
      //       };
      //
      //       const result = await axios.post(`/api/data-tag`, payload);
      //       const plantTimezone = result.data.data[0].plant_timezone;
      //
      //       // Setting default timezone for moment.js
      //       moment.tz.setDefault(plantTimezone);
      //       setPlantTimezone(plantTimezone);
      //       console.log(`Timezone set to: ${plantTimezone}`);
      //     }
      //   } catch (error) {
      //     console.error("Error fetching plant timezone:", error);
      //   }
      // }

      React.useEffect(() => {});

      const onDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        var date1 = moment.unix(start).tz(plantTimeZone);
        var date2 = moment.unix(end).tz(plantTimeZone);
        var diffDays = date1.diff(date2, "days");
        setFinanceDays(diffDays + 1);
      };

      const [paneState, setPaneState] = useState(false);
      const [financeDays, setFinanceDays] = useState(2);
      const [plant_id, setPlantID] = useState(treeObj["nodes"][0]["plant_id"]);
      const [meterName, setMeterName] = useState("");
      const logoUrl = {
        data:
          session.user.plants.logo == undefined
            ? process.env.DEFAULT_LOGO
            : session.user.plants.logo,
      };
      const configVariables = {
        data: {
          logo_url:
            session.user.plants.logo == undefined
              ? process.env.DEFAULT_LOGO
              : session.user.plants.logo,
          audit_flag: session.user.plants.audit,
        },
      };

      const auditListNo = audit_data["audit_list"].filter(
        (item) => item.plant_id == treeObj["nodes"][0]["plant_id"],
      )[0];

      const [dynamic_audit_data, set_dynamic_audit_data] =
        React.useState(audit_data);

      const handleChangeAuditData = (val) => {
        set_dynamic_audit_data(val);
      };

      const [auditList, setAuditList] = useState(auditListNo);
      const [auditID, setAuditID] = useState(
        auditListNo["data"].length > 0 ? auditListNo["data"][0]["audit_id"] : 0,
      );

      // const onDateChange = (start, end) => {
      //   console.log("aaaaaa>>>>>>>>>>")
      //   console.log("startfunction", start)
      //   console.log("endfunction", end)
      //   setStartDate(start);
      //   setEndDate(end);
      //   var date1 = moment.unix(start);
      //   var date2 =  moment.unix(end);
      //   var diffDays = date1.diff(date2,'days')
      //    setFinanceDays(diffDays + 1);
      // };
      const handleMeterName = (name) => {
        setMeterName(name);
      };

      const onMeterChange = (meter_id, plant_id) => {
        if (typeof window !== "undefined") {
          localStorage.setItem(
            treeObj["nodes"][0]["key"] + "currentMeter",
            meter_id,
          );
          localStorage.setItem(treeObj["nodes"][0]["key"] + "plant", plant_id);
        }
        setCurrentMeter(meter_id);
        setPlantID(plant_id);
      };

      const setPlantAuditList = (plantAuditList) => {
        setAuditList(plantAuditList[0]);
      };
      const changeAuditID = (id) => {
        setAuditID(id);
      };

      const saveLocal = (key, value) => {
        if (typeof window !== "undefined") {
          localStorage.setItem(serialize(key), serialize(value));
        }
      };
      const getLocal = (key, fallback) => {
        if (typeof window !== "undefined") {
          let error = false;

          if (
            localStorage.getItem(serialize(key)) != undefined ||
            localStorage.getItem(serialize(key)) != null
          ) {
            try {
              return deserialize(localStorage.getItem(serialize(key)));
            } catch (e) {
              console.log(e);

              error = true;
            }
          }
          if (!error && fallback != undefined) {
            return fallback;
          }

          return null;
        }
      };

      let sharedState = {
        onMeterChange,
        currentMeter,
        onDateChange,
        startDate,
        endDate,
        plant_id,
        meterName,
        handleMeterName,
        changeAuditID,
        auditID,
        setPlantAuditList,
        auditList,
        financeDays,
        plant_dict,
        plant_id_dict,
        treeObj,
        fullTreeObj,
        configVariables,
        logoUrl,
        changesetShowGlobal,
        changesetShowSV,
        changesetShowEV,
        changesetShowST,
        changesetShowUnnormalized,
        changeShowDeviceState,
        showGlobal,
        showSV,
        showEV,
        showST,
        showDeviceState,
        show_unnormalized,
        noiseTickets,
        closeTickets,
        dynamic_audit_data,
        handleChangeAuditData,
        handleNoiseTicketChange,
        handleCloseTicketChange,
        meterTagDict: meter_tag_dict,
        paneState,
        setPaneState,
        overViewMode,
        setOverViewMode,
        setStartDate,
        setEndDate,
        getParentNameFromUrl,
        createLinkForMeterData,
        createLinkSiblingCompare,
        saveLocal,
        getLocal,
        plantTimeZone,
      };
      return (
        <>
          <AppContext.Provider value={sharedState}>
            {children}
          </AppContext.Provider>
        </>
      );
    } catch {
      return (
        <>
          <Login />
        </>
      );
    }
  } else if (loading && !session) {
    return <p>Loading User Settings....</p>;
  } else {
    return (
      <>
        <Login />
      </>
    );
  }
}

export default function useAppContext() {
  return useContext(AppContext);
}
